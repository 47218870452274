$ait-dark-grey: rgb(64, 64, 64); // #404040
$ait-dark-grey-75: rgba(64, 64, 64, 0.75);
$dv-dark-grey: #333333;
$ait-light-grey: rgb(230, 231, 232);
$ait-orange: rgba(254, 140, 0, 1);
$ait-teal: rgba(72, 164, 167, 1);
$ait-white: #fff;
$ait-white-10: rgba(255, 255, 255, 0.1);
$ait-white-50: rgba(255, 255, 255, 0.5);
$ait-black: #000;

$primary: rgba(72, 164, 167, 1);
$primary-font: $ait-light-grey;
$accent: rgba(254, 140, 0, 1);
$accent-font: #fff;
$dark-theme-font: #ddd;

$primary-50: #e8eaf6;
$primary-100: #c5cae9;
$primary-200: #9fa8da;
$primary-300: #7986cb;
$primary-50-font: #000000;
$primary-100-font: #000000;
$primary-200-font: #000000;
$primary-300-font: #ffffff;
$grey-white: #ffffff;
$grey-black: #000000;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$grey-dark: #303030;
$grey-light-font: #000;
$grey-dark-font: #fff;
$base-font: #000;
$shadow: #000;
$skin-name: "material-dark" !default;
$accent: #00b0ff !default;
$accent-font: #000 !default;
$primary: #3f51b5 !default;
$primary-50: #e8eaf6 !default;
$primary-100: #c5cae9 !default;
$primary-200: #9fa8da !default;
$primary-300: #7986cb !default;
$primary-font: #fff !default;
$primary-50-font: #000 !default;
$primary-100-font: #000 !default;
$primary-200-font: #000 !default;
$primary-300-font: #fff !default;
$grey-white: #fff !default;
$grey-black: #000 !default;
$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;
$grey-dark: #303030 !default;
$grey-light-font: #000 !default;
$grey-dark-font: #fff !default;

$base-font: #000 !default;
$error-font: #ff6652;
$warning-font: #ff9800;
$info-font: #03a9f4;

$shadow: #000 !default;
$skin-name: "material-dark" !default;
@if not variable-exists("is-font-loaded") {
  //sass-lint:disable no-url-protocols,no-url-domains
  @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");
}

$is-font-loaded: "true" !default;
$font-family: "Source Sans Pro", "Arial", "sans-serif", "-apple-system",
  "BlinkMacSystemFont" !default;
$font-size: 12px !default;
$font-weight: normal !default;
$font-path: "common-images" !default;
$header-bg-color: $grey-white !default;
$header-gradient-color: none !default;
$header-border-color: $grey-black !default;
$header-font-color: $grey-light-font !default;
$header-icon-color: $grey-light-font !default;
$content-bg-color: $grey-white !default;
$content-border-color: $grey-black !default;
$content-font-color: $grey-light-font !default;
$default-bg-color: $grey-white !default;
$default-gradient-color: none !default;
$default-border-color: $grey-black !default;
$default-font-color: $grey-light-font !default;
$default-icon-color: $grey-light-font !default;
$hover-bg-color: $grey-200 !default;
$hover-gradient-color: none !default;
$hover-border-color: $grey-black !default;
$hover-font-color: $grey-light-font !default;
$hover-icon-color: $grey-light-font !default;
$active-bg-color: $grey-100 !default;
$active-gradient-color: none !default;
$active-border-color: $grey-black !default;
$active-font-color: $grey-light-font !default;
$active-icon-color: $grey-light-font !default;

$overlay-bg-color: #383838 !default;
$shadow-color: rgba(0, 0, 0, 0.4) !default;
$border-size: 1px !default;
$border-type: solid !default;
$border-radius: 0 !default;
$border-left-radius: 0 !default;
$border-right-radius: 0 !default;
$border-top-radius: 0 !default;
$border-bottom-radius: 0 !default;

/* Gap between columns, panels, cards etc */
$default-gap: 0.8rem;

$css: "" !default;
$imported-modules: () !default;

$green: rgb(0, 158, 15);
$red: rgb(207, 42, 39);

$password-strength-meter-message : #cccccc;
$password-strength-meter-background : #666666;